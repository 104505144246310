




































































































import { NakaCompanyConfiguration } from '@/store/interfaces/company';
import companyType from '@/store/types/company';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import NakaBinding from './forms/NakaBinding.vue';
import NakaCollection from '@/store/types/NakaCollection';
import { Collections } from '@/store/interfaces/NakaCollections';

export default Vue.extend({
  name: 'ProfileNaka',
  components: {
    NakaBinding,
  },
  data: () => ({
    edit: false,
  }),
  computed: {
    ...mapGetters({
      getSingleCompany: `companyModule/${companyType.getters.GET_SINGLE_COMPANY}`,
      getCurrencies: `nakaCollectionModule/${NakaCollection.getters.GET_CURRENCIES}`,
      getExchangesFees: `nakaCollectionModule/${NakaCollection.getters.GET_EXCHANGES_FEES}`,
      getTransactionFees: `nakaCollectionModule/${NakaCollection.getters.GET_TRANSACTION_FEES}`,
    }),
    fiat_currency_active_id(): string {
      return this.getCurrencies
        .find((currency: Collections) => currency.id === this.naka.fiat_currency_active_id)?.name;
    },
    fiat_currency_pending_id(): string {
      return this.getCurrencies
        .find((currency: Collections) => currency.id === this.naka.fiat_currency_pending_id)?.name;
    },
    settlement_currency_active_id(): string {
      return this.getCurrencies
        .find((currency: Collections) => currency.id === this.naka.settlement_currency_active_id)?.name;
    },
    settlement_currency_pending_id(): string {
      return this.getCurrencies
        .find((currency: Collections) => currency.id === this.naka.settlement_currency_pending_id)?.name;
    },
    exchange_fee_id(): string {
      return this.getExchangesFees
        .find((fee: Collections) => String(fee.id) === this.naka.exchange_fee_id)?.name;
    },
    transaction_fee_id(): string {
      return this.getTransactionFees
        .find((fee: Collections) => Number(fee.id) === Number(this.naka.transaction_fee_id))?.name;
    },
    naka(): NakaCompanyConfiguration {
      if (this.getSingleCompany.naka_company_configuration) {
        return this.getSingleCompany.naka_company_configuration;
      }
      return {
        registration_number: '',
        exchange_fee_id: '',
        transaction_fee_id: 0,
        mcc: 0,
        business_type: null,
        trr: null,
        website: null,
        number_of_employees: null,
        slogan: null,
        established_at: '',
        fiat_currency_active_id: '',
        fiat_currency_pending_id: '',
        settlement_currency_active_id: '',
        settlement_currency_pending_id: '',
      };
    },
  },
});
