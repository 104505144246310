














































































































import aggregatorModel from '@/components/Company/newCompany/companyInfo/forms/aggregatorModel.vue';
import IntermediarySelect from '@/components/Reusable/IntermediarySelect.vue';
import TaxpayerTypesSelect from '@/components/Reusable/TaxpayerTypesSelect.vue';
import { phoneMask } from '@/const/phoneNumber';
import { AggregatorModel } from '@/store/interfaces/company';
import { liquidationKind } from '@/store/interfaces/liquidations';
import companyTypes from '@/store/types/company';
import configurations from '@/store/types/configurations';
import liquidations from '@/store/types/liquidations';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

type valueName = {
  name: string;
  value: string;
};

export default Vue.extend({
  name: 'EnterpriceData',
  components: {
    aggregatorModel,
    IntermediarySelect,
    TaxpayerTypesSelect,
  },
  data: () => ({
    phoneMask,
    default_type_artificial_person: 1,
    type_artificial_person: '',
    type_artificial_person_array: [
      {
        name: 'Natural',
        value: 'natural',
      },
      {
        name: 'Jurídica',
        value: 'juridica',
      }
    ] as valueName[],
    aggregatorModelData: {
      name: '',
      company_profile_id: '',
      fixed_withholding: false,
    } as AggregatorModel,
  }),
  computed: {
    ...mapGetters({
      getNewCompanyParams: `companyModule/${companyTypes.getters.GET_NEW_COMPANY_PARAMS}`,
      getCompaniesProprofileList: `companyModule/${companyTypes.getters.GET_COMPANIES_PROFILE_LIST}`,
      liquidationKindList: `liquidationModule/${liquidations.getters.GET_LIQUIDATION_KIND_LIST}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    isAggregator(): boolean {
      return this.liquidationKindList.data.some(
        (kind: liquidationKind) =>
          kind.id === this.getNewCompanyParams.liquidation_kind_id &&
          kind.slug === 'agregador'
      );
    },
  },
  async mounted(): Promise<void> {
    await this.companiesProfileList();
    this.assignModel();
    this.assignAggregatorModel();
  },
  methods: {
    ...mapActions({
      companiesProfileList: `companyModule/${companyTypes.actions.COMPANIES_PROFILE}`,
    }),
    assignAggregatorModel(): void {
      this.aggregatorModelData = {
        name: this.getNewCompanyParams.name,
        company_profile_id: this.getNewCompanyParams.company_profile_id || '',
        fixed_withholding: this.getNewCompanyParams.fixed_withholding,
      };
    },
    assignModel(): void {
      if (!this.getNewCompanyParams.intermediary_id) {
        this.getNewCompanyParams.intermediary_id = '';
      }

      const artificial = this.getNewCompanyParams.artificial_person ? 'juridica' : 'natural';
      this.artificialPersonEvaluation(artificial);
    },
    artificialPersonEvaluation(value: string): void {
      if (value === 'juridica') {
        this.getNewCompanyParams.artificial_person = true;
        this.default_type_artificial_person = 2;
      } else {
        this.getNewCompanyParams.artificial_person = false;
        this.getNewCompanyParams.taxpayer_type = 'non';
        this.getNewCompanyParams.business_turn = '';
      }
    },
    changeValueOfAggregatorModel(val: boolean): void {
      if (val) {
        this.getNewCompanyParams.name = this.aggregatorModelData.name;
        this.getNewCompanyParams.company_profile_id =
          this.aggregatorModelData.company_profile_id;
        this.getNewCompanyParams.fixed_withholding =
          this.aggregatorModelData.fixed_withholding;
      } else {
        this.getNewCompanyParams.name = '';
        this.getNewCompanyParams.company_profile_id = '';
        this.getNewCompanyParams.fixed_withholding = false;
      }
    },
  },
  watch: {
    'getNewCompanyParams.name': {
      handler(val: string): void {
        this.aggregatorModelData.name = val;
      },
    },
    'getNewCompanyParams.company_profile_id': {
      handler(val: string): void {
        this.aggregatorModelData.company_profile_id = val;
      },
    },
    'getNewCompanyParams.fixed_withholding': {
      handler(val: boolean): void {
        this.aggregatorModelData.fixed_withholding = val;
      },
    },
    type_artificial_person: {
      immediate: true,
      deep: true,
      handler(val: string): void {
        this.artificialPersonEvaluation(val);
      },
    },
    isAggregator(val: boolean): void {
      this.changeValueOfAggregatorModel(val);
    },
  },
});
