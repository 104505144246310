import { responseCollection } from '@/store/interfaces/json-api/generics';
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  CompanyParams,
  companyProfile,
  CompanyState
} from '@/store/interfaces/company';
import { RootState } from '@/store/interfaces/rootState';
import companyType from '@/store/types/company';
import { http } from '@/util/HTTP';
import { TeipNotifications } from '@/util/teipNotifications';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import document from '../types/document';
import bankAccountModule from './childrens/bankAccount';

const namespaced = true;

const initialState = (): CompanyState => {
  return {
    companies: {
      data: [],
      pagination: {},
    },
    companiesProfile: {
      data: [],
      pagination: {},
    },
    company: {
      id: '',
      membership_status: 'inactive',
      membership_validation: 'yes',
      state_code: '',
      taxpayer_type: 'non',
      billing_comment: '',
      artificial_person: false,
      intermediary_id: '',
      intermediary: null,
      liquidation_kind: {
        name: '',
        slug: '',
      },
      email: '',
      phone: '',
      company_profile_id: '',
      bank_account: {
        account_number: '',
        account_type: 'current',
        bank_id: '',
        name: '',
        billing_type: 'fiscal_credit',
        account_holder: '',
        billing_address: '',
        contact: {
          email: '',
          name: '',
          phone: '',
          last_name: '',
          phone2: '',
        },
        contact_id: '',
      },
      business_name: '',
      commission: {
        rate: 0,
        start_at: '',
        liquidation_period: '',
        minimum_order_value: 0,
        renewal: 'automatic',
        fixed_fee_cents: 0,
        fixed_fee_currency: 'USD',
      },
      address: {
        description: '',
        phone: '',
        phone_alternative: '',
        state_code: '',
        zip_code: '',
        coordinate: {
          latitude: '',
          longitude: '',
        },
      },
      contacts: [],
      branches_count: 0,
      commerces_count: 0,
      created_at: '',
      business_turn: '',
      company_profile: null,
      liquidation_kind_id: null,
      name: '',
      fixed_withholding: false,
      naka_company_configuration: {
        registration_number: '',
        exchange_fee_id: '',
        transaction_fee_id: 0,
        mcc: 0,
        business_type: null,
        trr: null,
        website: null,
        number_of_employees: null,
        slogan: null,
        established_at: '',
        fiat_currency_active_id: '',
        fiat_currency_pending_id: '',
        settlement_currency_active_id: '',
        settlement_currency_pending_id: '',
      },
      business_activity_summary: null,
    },
    companyEditable: {
      business_name: '',
      artificial_person: false,
      membership_status: 'inactive',
      membership_validation: 'yes',
      taxpayer_type: 'non',
      state_code: '',
      business_turn: '',
      intermediary_id: '',
      liquidation_kind_id: '',
      billing_comment: '',
      name: '',
      fixed_withholding: false,
      business_activity_summary: '',
      email: '',
      phone: '',
      bank_account: {
        account_number: '',
        account_type: 'current',
        bank_id: '',
        billing_address: '',
        account_holder: '',
        billing_type: 'final_consumer',
        swift_bic: '',
      },
      contacts: [
        {
          name: '',
          last_name: '',
          email: '',
          phone: '',
          contact_kind_id: '',
        },
        {
          name: '',
          last_name: '',
          email: '',
          phone: '',
          contact_kind_id: '',
        }
      ],
      address: {
        description: '',
        phone: '',
        state_code: '',
        zip_code: '',
        coordinate: {
          latitude: '',
          longitude: '',
        },
      },
      commission: {
        rate: 3,
        start_at: '',
        liquidation_period: 'daily',
        minimum_order_value: 0.0,
        renewal: 'automatic',
        fixed_fee_cents: 0,
        fixed_fee_currency: 'USD',
      },
      naka_company_configuration: {
        registration_number: '',
        exchange_fee_id: '',
        transaction_fee_id: 0,
        mcc: 0,
        business_type: null,
        trr: null,
        website: null,
        number_of_employees: null,
        slogan: null,
        established_at: '',
        fiat_currency_active_id: '',
        fiat_currency_pending_id: '',
        settlement_currency_active_id: '',
        settlement_currency_pending_id: '',
      },
    },
    companies_by_commerce: {
      data: [],
      pagination: {},
    },
  };
};
const state: CompanyState = initialState();

const getters: GetterTree<CompanyState, RootState> = {
  [companyType.getters.GET_COMPANY]: state => {
    return state.companies;
  },
  [companyType.getters.GET_SINGLE_COMPANY]: state => {
    return state.company;
  },
  [companyType.getters.GET_NEW_COMPANY_PARAMS]: state => {
    return state.companyEditable;
  },
  [companyType.getters.GET_COMPANIES_BY_COMMERCE]: state => {
    return state.companies_by_commerce;
  },
  [companyType.getters.GET_COMPANIES_PROFILE_LIST]: state => {
    return state.companiesProfile;
  },
};
const mutations: MutationTree<CompanyState> = {
  [companyType.mutations.SET_COMPANY]: (
    state,
    companies: responseCollection<CompanyParams>
  ) => {
    state.companies.data = companies.data;
    state.companies.pagination = companies.meta.pagination;
  },
  [companyType.mutations.SET_CONTACT]: (state, { position, contact, }) => {
    state.companyEditable.contacts[position] = contact;
  },
  [companyType.mutations.SET_REMOVE_CONTACT]: (state, contact_kind_id) => {
    state.companyEditable.contacts = state.companyEditable.contacts.filter(
      (contact: any) => contact.contact_kind_id !== contact_kind_id
    );
  },
  [companyType.mutations.SET_SINGLE_COMPANY]: (state, company: CompanyParams) => {
    state.company = company;
  },
  [companyType.mutations.SET_PREPARE_EDITABLE_COMPANY]: (state, company: CompanyParams) => {
    state.companyEditable = {
      business_name: company.business_name || '',
      artificial_person: company.artificial_person,
      membership_status: company.membership_status,
      membership_validation: company.membership_validation,
      taxpayer_type: company.taxpayer_type,
      intermediary_id: company.intermediary_id || '',
      state_code: company.state_code,
      business_turn: company.business_turn,
      liquidation_kind_id: company.liquidation_kind_id || '',
      billing_comment: company.billing_comment,
      name: company.name,
      fixed_withholding: company.fixed_withholding,
      business_activity_summary: company.business_activity_summary || '',
      email: company.email,
      phone: company.phone,
      bank_account: company.bank_account || {
        account_number: '',
        account_type: 'current',
        bank_id: '',
        billing_address: '',
        account_holder: '',
        billing_type: 'final_consumer',
        swift_bic: '',
      },
      contacts: company.contacts?.map((contact) => {
        return {
          name: contact.name,
          last_name: contact.last_name,
          email: contact.email,
          phone: contact.phone,
          contact_kind_id: contact.contact_kind.id,
        };
      }) || [],
      address: company.address || {
        description: '',
        phone: '',
        state_code: '',
        zip_code: '',
        coordinate: {
          latitude: '',
          longitude: '',
        },
      },
      commission: company.commission,
      naka_company_configuration: {
        registration_number: '',
        exchange_fee_id: '',
        transaction_fee_id: 0,
        mcc: 0,
        business_type: null,
        trr: null,
        website: null,
        number_of_employees: null,
        slogan: null,
        established_at: '',
        fiat_currency_active_id: '',
        fiat_currency_pending_id: '',
        settlement_currency_active_id: '',
        settlement_currency_pending_id: '',
      },
    };
  },
  [companyType.mutations.SET_CLEAN_DATA]: state => {
    state.companyEditable = initialState().companyEditable;
  },
  [companyType.mutations.SET_COMPANIES_BY_COMMERCE]: (
    state,
    companies: responseCollection<CompanyParams>
  ) => {
    state.companies_by_commerce.data = companies.data;
    state.companies_by_commerce.pagination = companies.meta.pagination;
  },
  [companyType.mutations.SET_COMPANIES_PROFILE]: (
    state,
    companies: responseCollection<companyProfile>
  ) => {
    state.companiesProfile.data = companies.data;
    state.companiesProfile.pagination = companies.meta.pagination;
  },
};
const actions: ActionTree<CompanyState, RootState> = {
  [companyType.actions.UPDATE_COMPANY]: ({ commit, }, company_data) => {
    const sendData = {
      data: company_data.data,
    };
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/companies/${company_data.company_id}`, sendData)
        .then((res: AxiosResponse) => {
          commit(companyType.mutations.SET_SINGLE_COMPANY, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [companyType.actions.SAVE_COMPANY]: (
    { commit, dispatch, },
    { model, images, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/companies', { data: model, })
        .then((res: AxiosResponse) => {
          commit(companyType.mutations.SET_SINGLE_COMPANY, res.data.data);
          const dataToSaveImages = {
            company_id: res.data.data.id,
            contact_id: res.data.data.contacts[0].id,
            images,
          };
          dispatch(
            companyType.actions.SAVE_DOCUMENTS_OF_COMPANY,
            dataToSaveImages
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [companyType.actions.DELETE_COMPANY_CONTACT_DOCUMENTS]: (_, { id, referenceUrl, }) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/${referenceUrl}/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [companyType.actions.SAVE_DOCUMENTS_OF_COMPANY]: (
    _,
    { company_id, contact_id, images, }
  ) => {
    const documents = images;
    const notification = new TeipNotifications();
    documents.map((doc: any) => {
      if (doc.url === 'company_documents') {
        doc.register_document_data.company_id = company_id;
      } else {
        doc.register_document_data.contact_id = contact_id;
      }

      const headers = doc.data.headers;
      Axios.put(doc.data.url, doc.image, {
        headers,
      }).then(() => {
        http
          .post(`backoffice/${doc.url}`, {
            data: doc.register_document_data,
          })
          .catch(() => {
            notification.warning(doc.image.name, '¡Error al subir!');
          });
      });
    });
  },

  [companyType.actions.COMPANY_LIST]: (
    { commit, },
    queryParams = 'per_page=100'
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies?${queryParams}`)
        .then((res: AxiosResponse) => {
          resolve(res);
          commit(companyType.mutations.SET_COMPANY, res.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [companyType.actions.COMPANIES_PROFILE]: (
    { commit, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/company_profiles')
        .then((res: AxiosResponse) => {
          resolve(res);
          commit(companyType.mutations.SET_COMPANIES_PROFILE, res.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [companyType.actions.SINGLE_COMPANY]: ({ commit, }, id = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
          commit(companyType.mutations.SET_SINGLE_COMPANY, res.data.data);
          commit(companyType.mutations.SET_PREPARE_EDITABLE_COMPANY, res.data.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [companyType.actions.COMPANY_DOCUMENTS]: (
    { commit, },
    { url, data, }
  ) => {
    http
      .post(`backoffice/${url}`, {
        data,
      })
      .then((result: AxiosResponse) => {
        commit(
          `documentModule/${document.mutations.SET_SINGLE_DOCUMENT_IMAGE}`,
          result.data.data,
          {
            root: true,
          }
        );
      });
  },
  [companyType.actions.COMPANIES_BY_COMMERCE_LIST]: (
    { commit, },
    { query, commerce_id, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/commerces/${commerce_id}/companies?${query}`)
        .then((res: AxiosResponse) => {
          resolve(res);
          commit(companyType.mutations.SET_COMPANIES_BY_COMMERCE, res.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  modules: {
    bankAccount: bankAccountModule,
  },
};
