





















































































import company from '@/store/types/company';
import NakaCollection from '@/store/types/NakaCollection';
import { http } from '@/util/HTTP';
import { TeipNotifications } from '@/util/teipNotifications';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'CurrenciesFiatAndTransactions',
  props: {
    rules: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data: () => ({
    loading: true,
    fiatCurrencies: [],
    exchangesFees: [],
    transactionsFees: [],
  }),
  computed: {
    ...mapGetters({
      getNewCompanyParams: `companyModule/${company.getters.GET_NEW_COMPANY_PARAMS}`,
    }),
  },
  methods: {
    fiatCurrencyActiveList: async function () {
      try {
        const response = await http.get(
          'backoffice/naka/collections/currencies'
        );
        this.fiatCurrencies = response.data.data;
        this.$store.commit(
          `nakaCollectionModule/${NakaCollection.mutations.SET_CURRENCIES}`,
          response.data.data
        );
      } catch (error) {
        const notification = new TeipNotifications();
        notification.warning('Error', 'No se pudo cargar la lista de monedas');
      }
    },
    exchangeFeesList: async function () {
      try {
        const response = await http.get(
          'backoffice/naka/collections/exchange_fees'
        );
        this.exchangesFees = response.data.data;

        this.$store.commit(
          `nakaCollectionModule/${NakaCollection.mutations.SET_EXCHANGES_FEES}`,
          response.data.data
        );
      } catch (error) {
        const notification = new TeipNotifications();
        notification.warning(
          'Error',
          'No se pudo cargar la lista de tarifas de cambio'
        );
      }
    },
    transactionFeesList: async function () {
      try {
        const response = await http.get(
          'backoffice/naka/collections/transaction_fees'
        );
        this.transactionsFees = response.data.data;

        this.$store.commit(
          `nakaCollectionModule/${NakaCollection.mutations.SET_TRANSACTION_FEES}`,
          response.data.data
        );
      } catch (error) {
        const notification = new TeipNotifications();
        notification.warning(
          'Error',
          'No se pudo cargar la lista de tarifas de transacción'
        );
      }
    },
  },
  async mounted() {
    await this.fiatCurrencyActiveList();
    await this.exchangeFeesList();
    await this.transactionFeesList();
    this.loading = false;
  },
});
